import React from "react";
import PropTypes from "prop-types";
import Button from "./button";

const ButtonGrid = ({ buttons }) => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-10 p-10 mb-10">
    {buttons.map((button) => (
      <Button key={button.label} label={button.label} link={button.link} />
    ))}
  </div>
);

ButtonGrid.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
};

export default ButtonGrid;
