import * as React from "react";
import PropTypes from "prop-types";

const Paragraphs = ({ paragraphs }) => {
  return (
    <div className="my-10">
      {paragraphs.map((content) => (
        <p key={content.slice(0, 10)} className="mb-8">
          {content}
        </p>
      ))}
    </div>
  );
};

Paragraphs.propTypes = {
  paragraphs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Paragraphs;
