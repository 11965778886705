import React from "react";
import PropTypes from "prop-types";

const SpaceSafeImage = ({
  src,
  alt,
  height,
  width,
  imageClasses,
  wrapperClasses,
}) => {
  const defaultImageClasses = ["absolute", "max-w-full", "h-auto"];
  const additionalImageClasses = imageClasses.split(" ");
  const finalImageClasses = [...defaultImageClasses, ...additionalImageClasses];

  const defaultWrapperClasses = ["space-safe-wrapper"];
  const additionalWrapperClasses = wrapperClasses.split(" ");
  const finalWrapperClasses = [
    ...defaultWrapperClasses,
    ...additionalWrapperClasses,
  ];

  return (
    <div
      className={finalWrapperClasses.join(" ")}
      style={{ "--height": height, "--width": width }}
    >
      <img src={src} alt={alt} className={finalImageClasses.join(" ")}></img>
    </div>
  );
};

SpaceSafeImage.defaultProps = {
  imageClasses: "",
  wrapperClasses: "",
};

SpaceSafeImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  imageClasses: PropTypes.string,
  wrapperClasses: PropTypes.string,
};

export default SpaceSafeImage;
