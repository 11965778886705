import React from "react";
import PropTypes from "prop-types";
import { TransitionLink } from "gatsby-plugin-transition-link/components/TransitionLink";

const Button = ({ label, link }) => (
  <TransitionLink
    className="py-5 px-4 border border-black leading-none hover:bg-white hover:bg-opacity-50 transition duration-500 ease-in-out hover:underline"
    to={link}
    exit={{
      length: 0.5,
      state: { opacity: 0, y: 10 },
    }}
    entry={{
      delay: 0.5,
      state: { opacity: 0, y: 10 },
    }}
  >
    {label}
  </TransitionLink>
);

Button.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default Button;
