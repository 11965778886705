import * as React from 'react';
import Body from '../components/body';
import ButtonGrid from '../components/button-grid';
import Container from '../components/container';
import Layout from '../components/layout';
import PageTitle from '../components/page-title';
import Paragraphs from '../components/paragraphs';
import Seo from '../components/seo';
import SpaceSafeImage from '../components/space-safe-image';
import aboutImage from '../images/about-1.png';

const paragraphs = [
  `I have been mobile hairdressing since 2012 and it's my goal to provide a 
  professional salon service to all my clients without ever leaving their home. 
  I pay special attention to keeping your hair healthy and provide advice on what 
  service is best for you.`,
  `Over the years I have been a stylist for mums, children, brides, 
  families, grandparents, bridesmaids, the elderly, you name it!`,
  `I have a 5/5 rating on my facebook page along with 89 reviews. If you’d like to 
  ask any questions about my services or if you’d like to make an appointment feel 
  free to contact me on by phone, email or Facebook.`,
  `I consider myself to be quite a creative person and have a passion to make things in my spare time. I have a 
  love for all animals and can’t be separated from my beautiful pets.`,
  `People who know me would say I am kind and considerate, and that I always try to put my friends and 
  family before myself, I like to think this comes across with my mobile hairdressing because 
  I try my best to always put my client's needs first.`,
];

const AboutPage = ({ transitionStatus, entry, exit }) => {
  return (
    <Layout transitionStatus={transitionStatus}>
      <Seo
        title='About'
        description='Halo Hair Derbyshire is a mobile hairdressing service that covers the Derby/Derbyshire area. If you are looking for a truly relaxing and comfortable experience then look no further.'
      />
      <Container transitionStatus={transitionStatus} entry={entry} exit={exit}>
        <div className='w-10/12 md:w-6/12 mx-auto mt-16'>
          <SpaceSafeImage
            src={aboutImage}
            alt='Halo Hair Derbyshire Mobile Hairdresser'
            width={893}
            height={683}
            imageClasses='left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2'
          />
        </div>
        <Body>
          <div className='mb-20'>
            <PageTitle title='About Me' />
            <Paragraphs paragraphs={paragraphs} />
            <ButtonGrid
              buttons={[
                {
                  label: 'See my prices',
                  link: '/services',
                },
                {
                  label: 'Book an appointment',
                  link: '/contact',
                },
              ]}
            />
          </div>
        </Body>
      </Container>
    </Layout>
  );
};

export default AboutPage;
